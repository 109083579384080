<template>
  <div
    class="c-app flex-row align-items-center"
    style="background-color: #ebedef"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup class="shadow-lg">
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="handleLogin">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    v-model="email"
                    placeholder="Email"
                    autocomplete="username email"
                  >
                    <template #prepend-content
                      ><i class="bi bi-person"></i
                    ></template>
                  </CInput>
                  <CInput
                    v-model="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content
                      ><i class="bi bi-lock"></i
                    ></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <button
                        id="login-button"
                        type="submit"
                        class="btn btn-primary"
                      >
                        Login
                      </button>

                      <!--  <CButton @click="handleLogin" color="primary" class="px-4"

                        >Login</CButton
                      > -->
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0" disabled
                        >Forgot password?</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <div class="card bg-primary">
              <div
                class="card-body text-white d-flex align-items-center justify-content-center"
              >
                <div>
                  <img
                    src="@/assets/images/login-page-ched-logo.png"
                    alt=""
                    width="200"
                    length="200"
                  />
                </div>
              </div>
            </div>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    handleLogin() {
      let b = document.getElementById('login-button');
      b.innerHTML =
        '<span class="spinner-border spinner-border-sm mr-1"></span> Logging in';
      b.disabled = true;

      axios.get('/sanctum/csrf-cookie').then((response) => {
        // handle login
        // console.log(response);
        axios
          .post('/login', {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            // console.log(response);
            this.$store.commit('setAuthentication', true);
            this.$store.commit('setUser', response.data);
            this.pushRoute();
          })
          .catch((error) => {
            b.innerHTML = 'Login';
            b.disabled = false;
            console.log(error);
            toastr.error('Incorrect email or password', 'Error');
          });
      });
    },
    pushRoute() {
      let role = this.$store.state.user.role;

      if (role === 'Registrar') {
        this.$router.push({ name: 'CAVApplication' });
      } else if (role === 'Records') {
        this.$router.push({ name: 'RecordsDashboard' });
      } else if (role === 'NstpCoordinator') {
        this.$router.push({ name: 'NstpClient' });
      } else {
        this.$router.push({ path: '/dashboard' });
      }
    },
  },
  mounted() {},
};
</script>
